import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { usePrescribingHabits } from "../../api/useHcpProfileApi";
import { DataWindow, useDataWindows } from "../../api/useMetaDataApi";
import TableHeader from "../../components/Headers/TableHeader";
import SimpleTable from "../../components/Table/SimpleTable";
import { Column } from "../../components/Table/types";
import Tooltip from "../../components/Tooltip/Tooltip";
import {
  useClientHCPProfileConfiguration,
  useClientHCPProfilePrescribingHabitsConfiguration,
} from "../../data/global/useProductConfiguration";
import { useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import { PrescribingHabits as PrescribingHabitsType } from "../../generate";

interface Props {
  indication?: string | undefined;
}

const PrescribingHabits: React.FC<Props> = ({ indication }) => {
  const { t } = useTranslation();
  const { client: selectedClient, id } = useParams<{ client: string; id: string }>();
  const { dataKeys: allowedDataKeys } = useClientHCPProfilePrescribingHabitsConfiguration(selectedClient);
  const { data: dataWindows } = useDataWindows(selectedClient);
  const { filters } = useHcpLandscapeState();
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const timePeriod = filters.timePeriod || defaultDataWindow;
  let { data } = usePrescribingHabits(selectedClient, id, timePeriod, indication);
  const { isLoading } = usePrescribingHabits(selectedClient, id, timePeriod, indication);

  const filteredData = data;
  const keys = ["medicaidMarketShare", "commercialMarketShare", "medicareMarketShare", "allChannelsMarketShare"];

  const filteredLastObject =
    data && !isLoading
      ? Object.fromEntries(Object.entries(data[data.length - 1]).filter(([key]) => !keys.includes(key)))
      : {};

  filteredData?.pop();
  filteredData?.push(filteredLastObject);
  data = filteredData;

  const availableColumns: Column[] = useMemo(
    () => [
      {
        label: t("hcpProfile.product"),
        align: "left",
        dataKey: "product",
      },
      {
        label: t([
          `hcpProfile.commercialTrx.${timePeriod}.${selectedClient}`,
          `hcpProfile.commercialTrx.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "commercialTrx",
      },
      {
        label: t([
          `hcpProfile.commercialMarketShare.${timePeriod}.${selectedClient}`,
          `hcpProfile.commercialMarketShare.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "commercialMarketShare",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: t([
          `hcpProfile.medicaidTrx.${timePeriod}.${selectedClient}`,
          `hcpProfile.medicaidTrx.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "medicaidTrx",
      },
      {
        label: t([
          `hcpProfile.medicaidMarketShare.${timePeriod}.${selectedClient}`,
          `hcpProfile.medicaidMarketShare.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "medicaidMarketShare",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: t([
          `hcpProfile.medicareTrx.${timePeriod}.${selectedClient}`,
          `hcpProfile.medicareTrx.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "medicareTrx",
      },
      {
        label: t([
          `hcpProfile.medicareMarketShare.${timePeriod}.${selectedClient}`,
          `hcpProfile.medicareMarketShare.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "medicareMarketShare",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: t([
          `hcpProfile.allChannelsTrx.${timePeriod}.${selectedClient}`,
          `hcpProfile.allChannelsTrx.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "allChannelsTrx",
      },
      {
        label: t([
          `hcpProfile.allChannelsMarketShare.${timePeriod}.${selectedClient}`,
          `hcpProfile.allChannelsMarketShare.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "allChannelsMarketShare",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
    ],
    [selectedClient, t, timePeriod],
  );
  const columns: Column[] = useMemo(() => {
    return availableColumns.filter(({ dataKey }) => allowedDataKeys?.includes(dataKey as keyof PrescribingHabitsType));
  }, [availableColumns, allowedDataKeys]);

  const configHcpProfile = useClientHCPProfileConfiguration(selectedClient);
  const hideHabitsTotalMarket = configHcpProfile?.hideHabitsTotalMarket;

  const dataWithoutTotalMarket = hideHabitsTotalMarket ? data?.filter((item) => item.product !== "Total Market") : data;

  return (
    <div className="pb-6">
      <TableHeader title={t("hcpProfile.prescribingHabits")}>
        <Tooltip
          placement="bottom-end"
          tip={
            t([
              `hcpProfile.prescribingHabitsTooltip.${selectedClient}`,
              `hcpProfile.prescribingHabitsTooltip.default`,
            ]) as string
          }
        >
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </TableHeader>
      <div className="table-highlight-first-row">
        <SimpleTable columns={columns} data={dataWithoutTotalMarket} loading={isLoading} />
      </div>
    </div>
  );
};

export default PrescribingHabits;
