/**
 * Converts null values to undefined for API compatibility
 * @param value Any value that might be null
 * @returns The same value, but with null replaced by undefined
 */
export function nullToUndefined<T>(value: T | null): T | undefined {
  return value === null ? undefined : value;
}

/**
 * Converts null values to undefined in an object for API compatibility
 * @param obj An object that might contain null values
 * @returns A new object with all null values converted to undefined
 */
export function convertNullsToUndefined<T extends Record<string, any>>(obj: T): any {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === null ? undefined : value]));
}
