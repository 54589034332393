import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Column } from "../../components/Table/types";
import { useClientHCPLandscapeColumnsConfiguration } from "../../data/global/useProductConfiguration";
import { useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import { PrescFeed } from "../../generate";
import useGetRole, { FRM_ROLE } from "../../hooks/useGetRole";
import useHasHcpAccessAsFrm from "../../hooks/useHasHcpAccessAsFrm";

const hcpText = "hcpText" as const;
const hcpLink = "hcpLink" as const;

const useTableColumns = (client: string) => {
  const { t, i18n } = useTranslation();
  const { client: selectedClient } = useParams<{ client: string }>();
  const { data: role } = useGetRole(selectedClient);
  const isFrm = String(role) === FRM_ROLE;
  const {
    filters: { timePeriod, benificiaryType },
  } = useHcpLandscapeState();

  const hasHcpAccessAsFrm = useHasHcpAccessAsFrm(selectedClient, isFrm);
  const visibleAndOrderedColumnKeys = useClientHCPLandscapeColumnsConfiguration(client, hasHcpAccessAsFrm) || [];

  const allColumns: Column<keyof PrescFeed | "full_name">[] = [
    {
      label: `hcpLandscape.hcps.${client}`,
      align: "left",
      dataKey: "full_name",
      component: hasHcpAccessAsFrm ? hcpText : hcpLink,
    },
    {
      label: `hcpLandscape.favorableAccess.${client}`,
      align: "right",
      dataKey: "fav_pct",
      width: 180,
      format: {
        suffix: "%",
        decimalScale: 1,
        fixedDecimalScale: true,
      },
    },
    {
      label: t(`hcpLandscape.changeInFa.${client}`, { channel: benificiaryType }),
      align: "right",
      dataKey: "delta_fa",
      width: 160,
      format: {
        suffix: "%",
        decimalScale: 1,
        fixedDecimalScale: true,
      },
    },
    {
      label: `hcpLandscape.overallAccess.${client}`,
      align: "right",
      dataKey: "acc_pct",
      width: 170,
      format: {
        suffix: "%",
        decimalScale: 1,
        fixedDecimalScale: true,
      },
    },
    {
      label: timePeriod ? `hcpLandscape.marketTrx.${timePeriod}.${client}` : "Loading...",
      align: "right",
      dataKey: "mkt_trx",
      width: 130,
      format: { thousandSeparator: true },
    },
    {
      label: timePeriod ? `hcpLandscape.productTrx.${timePeriod}.${client}` : "Loading...",
      translationParams: { product: client },
      align: "right",
      dataKey: "prod_trx",
      width: 150,
      format: { thousandSeparator: true },
    },
    {
      label: timePeriod
        ? t(`hcpLandscape.compProdTrx.${timePeriod}.${client}`, { channel: benificiaryType })
        : "Loading...",
      translationParams: { product: client },
      align: "right",
      dataKey: "comp_prod_trx",
      width: 150,
      format: {
        suffix: "%",
        decimalScale: 1,
        fixedDecimalScale: true,
      },
    },
    {
      label: timePeriod ? `hcpLandscape.productShare.${timePeriod}.${client}` : "Loading...",
      translationParams: { product: client },
      align: "right",
      dataKey: "prod_share",
      width: 150,
      format: {
        suffix: "%",
        decimalScale: 1,
        fixedDecimalScale: true,
      },
    },
  ];

  // Filter and sort columns according to visibleAndOrderedColumnKeys
  const sortedColumns = visibleAndOrderedColumnKeys
    .map((key) => allColumns.find((col) => col.dataKey === key))
    .filter(Boolean) as Column<keyof PrescFeed | "full_name">[];

  return { columns: sortedColumns };
};

export default useTableColumns;
