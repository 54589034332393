/* eslint-disable no-console */
import React, { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePayerLandscapeDetailDropdowns } from "../../api/usePayerLandscapeDetailApi";
import RangeInput from "../../components/Inputs/RangeInput";
import Select from "../../components/Inputs/Select";
import FilterModal from "../../components/Modal/FilterModal";
import { useModal } from "../../components/Modal/useModal";
import SearchBox from "../../components/SearchBox/SearchBox";
import { useClientPayerLandscapeConfiguration } from "../../data/global/useProductConfiguration";
import {
  clearDetailFilters as clearAction,
  updateDetailFilters as filterAction,
  updateSharedFilters as filterSharedAction,
} from "../../data/payerLandscape/actions";
import { usePayerLandscapeDispatch, usePayerLandscapeState } from "../../data/payerLandscape/hooks";
import payerLandscapeReducer from "../../data/payerLandscape/reducer";
import useGetRole, { FRM_ROLE } from "../../hooks/useGetRole";
import { arrayToOptions } from "../../utils/arrayToOptions";
import { nullToUndefined } from "../../utils/typeUtils";

const DetailFiltersModal: React.FC<{ client: string; product?: string | null }> = ({ client, product }) => {
  const { t } = useTranslation();
  const { setModal } = useModal();
  const [refreshKey, setRefreshKey] = useState(0);
  const { data: role } = useGetRole(client);
  const isFRM = String(role) === FRM_ROLE;
  const dropdownQuery = usePayerLandscapeDetailDropdowns(client);
  const dropdowns = dropdownQuery.data;
  const coverageOptions = arrayToOptions(dropdowns?.coverageStatus);
  const relativeStatusOptions = arrayToOptions(dropdowns?.relativeStatus);

  // hides fields from Advanced Search if hidden from table
  const {
    detailedViewProductComp: { ...payerLandscapeKeys },
  } = useClientPayerLandscapeConfiguration(client);
  const { dataKeys } = payerLandscapeKeys;

  const relativeStatusHidden = !dataKeys?.includes("rel_status");
  const monthlyChangeHidden = !dataKeys?.includes("share_delta");
  const tclMarketShareHidden = !dataKeys?.includes("mkt_share");

  //Replicate redux state locally so we don't filter until we apply the filters
  const payerLandscapeState = usePayerLandscapeState();
  const { updateSharedFilters, updateDetailFilters, clearDetailFilters } = usePayerLandscapeDispatch();
  const [{ detailFilters, sharedFilters }, dispatch] = useReducer(payerLandscapeReducer, payerLandscapeState);

  //Clear all advanced filters and force refresh input ranges
  const clear = () => {
    dispatch(clearAction());
    clearDetailFilters();
    setRefreshKey((key) => key + 1);
  };

  //Update redux's state with local state and close modal
  const filter = () => {
    updateDetailFilters(detailFilters);
    updateSharedFilters(sharedFilters);
    setModal(undefined);
  };

  return (
    <FilterModal onClear={clear} onFilter={filter} title={t("payerLandscape.detailedViewFilters")}>
      <SearchBox
        value={sharedFilters.planName}
        handleChange={(value) => dispatch(filterSharedAction({ planName: value }))}
        label={t("payerLandscape.planName")}
      />
      <RangeInput
        key={`lives-${refreshKey}`}
        range={{
          min: detailFilters.minimumLives,
          max: detailFilters.maximumLives,
          rangeType: detailFilters.rangeTypeLives!,
        }}
        setRange={(range) =>
          dispatch(
            filterAction({
              minimumLives: nullToUndefined(range.min),
              maximumLives: nullToUndefined(range.max),
              rangeTypeLives: range.rangeType,
            }),
          )
        }
        label={t("payerLandscape.lives")}
        allowNegative={false}
      />
      <Select
        minWidth={250}
        value={detailFilters.coverageStatus}
        handleChange={(value) => dispatch(filterAction({ coverageStatus: value }))}
        label={t([`payerLandscape.coverageStatus.${client}`, `payerLandscape.coverageStatus.default`])}
        options={coverageOptions}
      />

      {!relativeStatusHidden && (
        <Select
          minWidth={250}
          value={detailFilters.relativeStatus}
          handleChange={(value) => dispatch(filterAction({ relativeStatus: value }))}
          label={t([`payerLandscape.relativeStatus.${client}`, `payerLandscape.relativeStatus.default`])}
          options={relativeStatusOptions}
        />
      )}
      {!isFRM && (
        <>
          <RangeInput
            key={`trx-${refreshKey}`}
            range={{
              min: detailFilters.minimumTrx,
              max: detailFilters.maximumTrx,
              rangeType: detailFilters.rangeTypeTrx!,
            }}
            setRange={(range) =>
              dispatch(
                filterAction({
                  minimumTrx: nullToUndefined(range.min),
                  maximumTrx: nullToUndefined(range.max),
                  rangeTypeTrx: range.rangeType,
                }),
              )
            }
            label={t(
              [
                `payerLandscape.productTRX.${sharedFilters.timePeriod}.${client}`,
                `payerLandscape.productTRX.${sharedFilters.timePeriod}.default`,
              ],
              {
                product: product || client,
              },
            )}
            allowNegative={false}
          />
          <RangeInput
            key={`marketTrx-${refreshKey}`}
            range={{
              min: detailFilters.minimumMarketTrx,
              max: detailFilters.maximumMarketTrx,
              rangeType: detailFilters.rangeTypeMarketTrx!,
            }}
            setRange={(range) =>
              dispatch(
                filterAction({
                  minimumMarketTrx: nullToUndefined(range.min),
                  maximumMarketTrx: nullToUndefined(range.max),
                  rangeTypeMarketTrx: range.rangeType,
                }),
              )
            }
            label={t([
              `payerLandscape.marketTrx.${sharedFilters.timePeriod}.${client}`,
              `payerLandscape.marketTrx.${sharedFilters.timePeriod}.default`,
            ])}
            allowNegative={false}
          />
          {!tclMarketShareHidden && (
            <RangeInput
              key={`marketShare-${refreshKey}`}
              range={{
                min: detailFilters.minimumMarketShare,
                max: detailFilters.maximumMarketShare,
                rangeType: detailFilters.rangeTypeMarketShare!,
              }}
              setRange={(range) =>
                dispatch(
                  filterAction({
                    minimumMarketShare: nullToUndefined(range.min),
                    maximumMarketShare: nullToUndefined(range.max),
                    rangeTypeMarketShare: range.rangeType,
                  }),
                )
              }
              label={t(`payerLandscape.marketShare.${sharedFilters.timePeriod}.${client}`, { product: product })}
              allowNegative={false}
            />
          )}
          {!monthlyChangeHidden && (
            <RangeInput
              key={`monthlyDelta-${refreshKey}`}
              range={{
                min: detailFilters.minimumMonthlyDelta,
                max: detailFilters.maximumMonthlyDelta,
                rangeType: detailFilters.rangeTypeMonthlyDelta!,
              }}
              setRange={(range) =>
                dispatch(
                  filterAction({
                    minimumMonthlyDelta: nullToUndefined(range.min),
                    maximumMonthlyDelta: nullToUndefined(range.max),
                    rangeTypeMonthlyDelta: range.rangeType,
                  }),
                )
              }
              label={t("payerLandscape.monthlyChange")}
              allowNegative={false}
            />
          )}
        </>
      )}
    </FilterModal>
  );
};

export default DetailFiltersModal;
