import { useAuth0 } from "@developertown/oidc-provider";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import useAuth0Api from "../../api/useAuth0Api";
import { useGetPreferences, usePutPreferences } from "../../api/usePreferencesApi";
import PageHeader from "../../components/Headers/PageHeader";
import { useModal } from "../../components/Modal/useModal";
import { useGlobalState } from "../../data/global/hooks";
import { convertNullsToUndefined } from "../../utils/typeUtils";
import UserInfo from "./UserInfo";

const UserProfile: React.FC = () => {
  const [t] = useTranslation();
  const Auth0 = useAuth0();
  const { setModal } = useModal();
  const { rootGeoName, rootGeoType } = useGlobalState();
  const { resetPassword } = useAuth0Api();
  const prefQuery = useGetPreferences();
  const updatePrefs = usePutPreferences();
  const user = Auth0.user;
  const showPreferences = false;
  const togglePI = () => {
    const postData = { ...prefQuery.data, showPI: !prefQuery.data?.showPI };
    updatePrefs.mutate(convertNullsToUndefined(postData));
  };

  return (
    <div>
      <PageHeader title={t("userProfile.headerWithName", { name: user?.name })}>
        <button onClick={() => Auth0.logout({ extraQueryParams: { returnTo: window.location.origin } })}>
          {t("common.logout")}
        </button>
      </PageHeader>

      <div className="flex lg:space-x-6 flex-col lg:flex-row justify-between mt-8">
        <div className="lg:w-1/2 xl:w-4/12">
          <div className="w-full flex items-baseline justify-between space-x-10 border-b-2 border-brand pb-2">
            <h2 className="text-2xl font-bold text-brand">{t("userProfile.userInformation")}</h2>
            <button
              onClick={() => setModal(<div>Coming soon</div>)}
              className="text-primary hover:underline font-bold focus:ring hidden"
            >
              {t("userProfile.edit")}
            </button>
          </div>
          <div className="flex flex-col space-y-4 py-4">
            {user?.name && <UserInfo label={t("userProfile.name")} value={user?.name} />}
            {user?.email && <UserInfo label={t("userProfile.email")} value={user?.email} />}
            <div className="hidden">
              <button
                onClick={() => resetPassword(user?.email)}
                className="text-primary hover:underline font-bold focus:ring"
              >
                {t("userProfile.changePassword")}
              </button>
            </div>
          </div>

          <h2 className="mt-8 text-2xl font-bold text-brand border-b-2 border-brand pb-2">
            {t("userProfile.territories")}
          </h2>
          <ul className="mt-3 mb-6 space-y-2">
            <li>{t("userProfile.geoTypeAndName", { type: rootGeoType, name: rootGeoName })}</li>
          </ul>
        </div>
        <div className="lg:w-1/2 xl:w-5/12">
          {showPreferences && (
            <div className="bg-light-gray p-4 w-full">
              <h2 className="text-2xl font-bold text-brand border-b-2 border-brand pb-2">
                {t("userProfile.preferences")}
              </h2>
              <div className="flex flex-col space-y-2 mt-3">
                {prefQuery.data && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={prefQuery.data.showPI}
                        onChange={() => togglePI()}
                        name="includePI"
                        color="primary"
                      />
                    }
                    label={t("userProfile.includePI")}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
