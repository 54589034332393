/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  GeographyTreeviewControllerResponse,
  GeographyTreeviewControllerResponseFromJSON,
  GeographyTreeviewControllerResponseToJSON,
  StringControllerResponse,
  StringControllerResponseFromJSON,
  StringControllerResponseToJSON,
  UserPreferencesControllerResponse,
  UserPreferencesControllerResponseFromJSON,
  UserPreferencesControllerResponseToJSON,
} from "../models";

export interface PermissionsGeographyTreeviewGetRequest {
  client: string;
  apiVersion?: string;
}

export interface PermissionsPreferencesGetRequest {
  apiVersion?: string;
}

export interface PermissionsPreferencesPutRequest {
  email?: string;
  showPI?: boolean;
  apiVersion?: string;
}

export interface PermissionsRoleGetRequest {
  client: string;
  apiVersion?: string;
}

/**
 *
 */
export class PermissionsApi extends runtime.BaseAPI {
  /**
   */
  async permissionsGeographyTreeviewGetRaw(
    requestParameters: PermissionsGeographyTreeviewGetRequest,
  ): Promise<runtime.ApiResponse<GeographyTreeviewControllerResponse>> {
    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling permissionsGeographyTreeviewGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Permissions/GeographyTreeview`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => GeographyTreeviewControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async permissionsGeographyTreeviewGet(
    requestParameters: PermissionsGeographyTreeviewGetRequest,
  ): Promise<GeographyTreeviewControllerResponse> {
    const response = await this.permissionsGeographyTreeviewGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async permissionsPreferencesGetRaw(
    requestParameters: PermissionsPreferencesGetRequest,
  ): Promise<runtime.ApiResponse<UserPreferencesControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Permissions/Preferences`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferencesControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async permissionsPreferencesGet(
    requestParameters: PermissionsPreferencesGetRequest,
  ): Promise<UserPreferencesControllerResponse> {
    const response = await this.permissionsPreferencesGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async permissionsPreferencesPutRaw(
    requestParameters: PermissionsPreferencesPutRequest,
  ): Promise<runtime.ApiResponse<UserPreferencesControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters["Email"] = requestParameters.email;
    }

    if (requestParameters.showPI !== undefined) {
      queryParameters["ShowPI"] = requestParameters.showPI;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Permissions/Preferences`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferencesControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async permissionsPreferencesPut(
    requestParameters: PermissionsPreferencesPutRequest,
  ): Promise<UserPreferencesControllerResponse> {
    const response = await this.permissionsPreferencesPutRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async permissionsRoleGetRaw(
    requestParameters: PermissionsRoleGetRequest,
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling permissionsRoleGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Permissions/Role`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async permissionsRoleGet(requestParameters: PermissionsRoleGetRequest): Promise<StringControllerResponse> {
    const response = await this.permissionsRoleGetRaw(requestParameters);
    return await response.value();
  }
}
