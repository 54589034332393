/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ComparisonDropdownsControllerResponse,
  ComparisonDropdownsControllerResponseFromJSON,
  ComparisonDropdownsControllerResponseToJSON,
  ComparisonFeedIEnumerableControllerResponse,
  ComparisonFeedIEnumerableControllerResponseFromJSON,
  ComparisonFeedIEnumerableControllerResponseToJSON,
  DetailDropdownsControllerResponse,
  DetailDropdownsControllerResponseFromJSON,
  DetailDropdownsControllerResponseToJSON,
  MarketSummaryIEnumerableControllerResponse,
  MarketSummaryIEnumerableControllerResponseFromJSON,
  MarketSummaryIEnumerableControllerResponseToJSON,
  PlanDataControllerResponse,
  PlanDataControllerResponseFromJSON,
  PlanDataControllerResponseToJSON,
  PlanFeedIEnumerableControllerResponse,
  PlanFeedIEnumerableControllerResponseFromJSON,
  PlanFeedIEnumerableControllerResponseToJSON,
  SortDirection,
  SortDirectionFromJSON,
  SortDirectionToJSON,
  StringControllerResponse,
  StringControllerResponseFromJSON,
  StringControllerResponseToJSON,
  StringIEnumerableControllerResponse,
  StringIEnumerableControllerResponseFromJSON,
  StringIEnumerableControllerResponseToJSON,
  TopHealthcareProvidersIEnumerableControllerResponse,
  TopHealthcareProvidersIEnumerableControllerResponseFromJSON,
  TopHealthcareProvidersIEnumerableControllerResponseToJSON,
} from "../models";

export interface PayerClientDropdownGetRequest {
  apiVersion?: string;
}

export interface PayerComparisonDropdownsGetRequest {
  client?: string;
  apiVersion?: string;
}

export interface PayerComparisonExcelGetRequest {
  resultNumber: number;
  planName?: string;
  minimumLives?: number;
  maximumLives?: number;
  client?: string;
  level?: string;
  geography?: string;
  beneficiaryType?: string;
  minimumTransactions?: number;
  maximumTransactions?: number;
  indication?: string;
  columnKeys?: Array<string>;
  columnNames?: Array<string>;
  skip?: number;
  sortSortField?: string;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface PayerComparisonGetRequest {
  planName?: string;
  minimumLives?: number;
  maximumLives?: number;
  client?: string | null;
  level?: string | null;
  geography?: string | null;
  beneficiaryType?: string;
  minimumTransactions?: number;
  maximumTransactions?: number;
  indication?: string | null;
  resultNumber?: number;
  skip?: number;
  sortSortField?: string;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface PayerDetailDropdownsGetRequest {
  client?: string;
  apiVersion?: string;
}

export interface PayerDetailExcelGetRequest {
  planName?: string;
  product?: string;
  client?: string;
  minimumLives?: number;
  maximumLives?: number;
  geography?: string;
  level?: string;
  beneficiaryType?: string;
  timePeriod?: string;
  coverageStatus?: string;
  relativeStatus?: string;
  minimumTrx?: number;
  maximumTrx?: number;
  minimumMarketTrx?: number;
  maximumMarketTrx?: number;
  minimumMarketShare?: number;
  maximumMarketShare?: number;
  minimumMonthlyDelta?: number;
  maximumMonthlyDelta?: number;
  columnKeys?: Array<string>;
  columnNames?: Array<string>;
  skip?: number;
  sortSortField?: string;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface PayerDetailGetRequest {
  planName?: string;
  product?: string | null;
  client?: string | null;
  minimumLives?: number;
  maximumLives?: number;
  geography?: string | null;
  level?: string | null;
  beneficiaryType?: string;
  timePeriod?: string | null;
  coverageStatus?: string;
  indication?: string | null;
  relativeStatus?: string;
  minimumTrx?: number;
  maximumTrx?: number;
  minimumMarketTrx?: number;
  maximumMarketTrx?: number;
  minimumMarketShare?: number;
  maximumMarketShare?: number;
  minimumMonthlyDelta?: number;
  maximumMonthlyDelta?: number;
  minimumCompTrx?: number;
  maximumCompTrx?: number;
  resultNumber?: number;
  skip?: number;
  sortSortField?: string;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface PayerPlanProfileAdditionalCoverageUrlGetRequest {
  planID: string;
  client: string;
  indication?: string;
  apiVersion?: string;
}

export interface PayerPlanProfileMarketSummaryGetRequest {
  planID: string;
  geographyType: string;
  geography: string;
  client: string;
  indication?: string;
  timePeriod?: string;
  apiVersion?: string;
}

export interface PayerPlanProfilePlanDataGetRequest {
  planID: string;
  geographyType: string;
  geography: string;
  client: string;
  indication?: string;
  timePeriod?: string;
  apiVersion?: string;
}

export interface PayerPlanProfileTopHealthcareProvidersExcelGetRequest {
  planID: string;
  geographyType: string;
  geography: string;
  client: string;
  indication?: string;
  prescID?: string;
  timePeriod?: string;
  columnKeys?: Array<string>;
  columnNames?: Array<string>;
  sortSortField?: string;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

export interface PayerPlanProfileTopHealthcareProvidersGetRequest {
  planID: string;
  geographyType: string;
  geography: string;
  client: string;
  resultNumber: number;
  indication?: string;
  prescID?: string;
  timePeriod?: string;
  skip?: number;
  sortSortField?: string;
  sortSortDirection?: SortDirection;
  apiVersion?: string;
}

/**
 *
 */
export class PayerApi extends runtime.BaseAPI {
  /**
   */
  async payerClientDropdownGetRaw(
    requestParameters: PayerClientDropdownGetRequest,
  ): Promise<runtime.ApiResponse<StringIEnumerableControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/ClientDropdown`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringIEnumerableControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async payerClientDropdownGet(
    requestParameters: PayerClientDropdownGetRequest,
  ): Promise<StringIEnumerableControllerResponse> {
    const response = await this.payerClientDropdownGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerComparisonDropdownsGetRaw(
    requestParameters: PayerComparisonDropdownsGetRequest,
  ): Promise<runtime.ApiResponse<ComparisonDropdownsControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.client !== undefined) {
      queryParameters["client"] = requestParameters.client;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/ComparisonDropdowns`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ComparisonDropdownsControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async payerComparisonDropdownsGet(
    requestParameters: PayerComparisonDropdownsGetRequest,
  ): Promise<ComparisonDropdownsControllerResponse> {
    const response = await this.payerComparisonDropdownsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerComparisonExcelGetRaw(
    requestParameters: PayerComparisonExcelGetRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.resultNumber === null || requestParameters.resultNumber === undefined) {
      throw new runtime.RequiredError(
        "resultNumber",
        "Required parameter requestParameters.resultNumber was null or undefined when calling payerComparisonExcelGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.planName !== undefined) {
      queryParameters["PlanName"] = requestParameters.planName;
    }

    if (requestParameters.minimumLives !== undefined) {
      queryParameters["MinimumLives"] = requestParameters.minimumLives;
    }

    if (requestParameters.maximumLives !== undefined) {
      queryParameters["MaximumLives"] = requestParameters.maximumLives;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.level !== undefined) {
      queryParameters["Level"] = requestParameters.level;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.beneficiaryType !== undefined) {
      queryParameters["BeneficiaryType"] = requestParameters.beneficiaryType;
    }

    if (requestParameters.minimumTransactions !== undefined) {
      queryParameters["MinimumTransactions"] = requestParameters.minimumTransactions;
    }

    if (requestParameters.maximumTransactions !== undefined) {
      queryParameters["MaximumTransactions"] = requestParameters.maximumTransactions;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.columnKeys) {
      queryParameters["ColumnKeys"] = requestParameters.columnKeys;
    }

    if (requestParameters.columnNames) {
      queryParameters["ColumnNames"] = requestParameters.columnNames;
    }

    if (requestParameters.resultNumber !== undefined) {
      queryParameters["ResultNumber"] = requestParameters.resultNumber;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/Comparison/Excel`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async payerComparisonExcelGet(requestParameters: PayerComparisonExcelGetRequest): Promise<void> {
    await this.payerComparisonExcelGetRaw(requestParameters);
  }

  /**
   */
  async payerComparisonGetRaw(
    requestParameters: PayerComparisonGetRequest,
  ): Promise<runtime.ApiResponse<ComparisonFeedIEnumerableControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.planName !== undefined) {
      queryParameters["PlanName"] = requestParameters.planName;
    }

    if (requestParameters.minimumLives !== undefined) {
      queryParameters["MinimumLives"] = requestParameters.minimumLives;
    }

    if (requestParameters.maximumLives !== undefined) {
      queryParameters["MaximumLives"] = requestParameters.maximumLives;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.level !== undefined) {
      queryParameters["Level"] = requestParameters.level;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.beneficiaryType !== undefined) {
      queryParameters["BeneficiaryType"] = requestParameters.beneficiaryType;
    }

    if (requestParameters.minimumTransactions !== undefined) {
      queryParameters["MinimumTransactions"] = requestParameters.minimumTransactions;
    }

    if (requestParameters.maximumTransactions !== undefined) {
      queryParameters["MaximumTransactions"] = requestParameters.maximumTransactions;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.resultNumber !== undefined) {
      queryParameters["ResultNumber"] = requestParameters.resultNumber;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/Comparison`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ComparisonFeedIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async payerComparisonGet(
    requestParameters: PayerComparisonGetRequest,
  ): Promise<ComparisonFeedIEnumerableControllerResponse> {
    const response = await this.payerComparisonGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerDetailDropdownsGetRaw(
    requestParameters: PayerDetailDropdownsGetRequest,
  ): Promise<runtime.ApiResponse<DetailDropdownsControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.client !== undefined) {
      queryParameters["client"] = requestParameters.client;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/DetailDropdowns`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => DetailDropdownsControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async payerDetailDropdownsGet(
    requestParameters: PayerDetailDropdownsGetRequest,
  ): Promise<DetailDropdownsControllerResponse> {
    const response = await this.payerDetailDropdownsGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerDetailExcelGetRaw(requestParameters: PayerDetailExcelGetRequest): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    if (requestParameters.planName !== undefined) {
      queryParameters["PlanName"] = requestParameters.planName;
    }

    if (requestParameters.product !== undefined) {
      queryParameters["Product"] = requestParameters.product;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.minimumLives !== undefined) {
      queryParameters["MinimumLives"] = requestParameters.minimumLives;
    }

    if (requestParameters.maximumLives !== undefined) {
      queryParameters["MaximumLives"] = requestParameters.maximumLives;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.level !== undefined) {
      queryParameters["Level"] = requestParameters.level;
    }

    if (requestParameters.beneficiaryType !== undefined) {
      queryParameters["BeneficiaryType"] = requestParameters.beneficiaryType;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.coverageStatus !== undefined) {
      queryParameters["CoverageStatus"] = requestParameters.coverageStatus;
    }

    if (requestParameters.relativeStatus !== undefined) {
      queryParameters["RelativeStatus"] = requestParameters.relativeStatus;
    }

    if (requestParameters.minimumTrx !== undefined) {
      queryParameters["MinimumTrx"] = requestParameters.minimumTrx;
    }

    if (requestParameters.maximumTrx !== undefined) {
      queryParameters["MaximumTrx"] = requestParameters.maximumTrx;
    }

    if (requestParameters.minimumMarketTrx !== undefined) {
      queryParameters["MinimumMarketTrx"] = requestParameters.minimumMarketTrx;
    }

    if (requestParameters.maximumMarketTrx !== undefined) {
      queryParameters["MaximumMarketTrx"] = requestParameters.maximumMarketTrx;
    }

    if (requestParameters.minimumMarketShare !== undefined) {
      queryParameters["MinimumMarketShare"] = requestParameters.minimumMarketShare;
    }

    if (requestParameters.maximumMarketShare !== undefined) {
      queryParameters["MaximumMarketShare"] = requestParameters.maximumMarketShare;
    }

    if (requestParameters.minimumMonthlyDelta !== undefined) {
      queryParameters["MinimumMonthlyDelta"] = requestParameters.minimumMonthlyDelta;
    }

    if (requestParameters.maximumMonthlyDelta !== undefined) {
      queryParameters["MaximumMonthlyDelta"] = requestParameters.maximumMonthlyDelta;
    }

    if (requestParameters.columnKeys) {
      queryParameters["ColumnKeys"] = requestParameters.columnKeys;
    }

    if (requestParameters.columnNames) {
      queryParameters["ColumnNames"] = requestParameters.columnNames;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/Detail/Excel`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async payerDetailExcelGet(requestParameters: PayerDetailExcelGetRequest): Promise<void> {
    await this.payerDetailExcelGetRaw(requestParameters);
  }

  /**
   */
  async payerDetailGetRaw(
    requestParameters: PayerDetailGetRequest,
  ): Promise<runtime.ApiResponse<PlanFeedIEnumerableControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.planName !== undefined) {
      queryParameters["PlanName"] = requestParameters.planName;
    }

    if (requestParameters.product !== undefined) {
      queryParameters["Product"] = requestParameters.product;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.minimumLives !== undefined) {
      queryParameters["MinimumLives"] = requestParameters.minimumLives;
    }

    if (requestParameters.maximumLives !== undefined) {
      queryParameters["MaximumLives"] = requestParameters.maximumLives;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.level !== undefined) {
      queryParameters["Level"] = requestParameters.level;
    }

    if (requestParameters.beneficiaryType !== undefined) {
      queryParameters["BeneficiaryType"] = requestParameters.beneficiaryType;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.coverageStatus !== undefined) {
      queryParameters["CoverageStatus"] = requestParameters.coverageStatus;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.relativeStatus !== undefined) {
      queryParameters["RelativeStatus"] = requestParameters.relativeStatus;
    }

    if (requestParameters.minimumTrx !== undefined) {
      queryParameters["MinimumTrx"] = requestParameters.minimumTrx;
    }

    if (requestParameters.maximumTrx !== undefined) {
      queryParameters["MaximumTrx"] = requestParameters.maximumTrx;
    }

    if (requestParameters.minimumMarketTrx !== undefined) {
      queryParameters["MinimumMarketTrx"] = requestParameters.minimumMarketTrx;
    }

    if (requestParameters.maximumMarketTrx !== undefined) {
      queryParameters["MaximumMarketTrx"] = requestParameters.maximumMarketTrx;
    }

    if (requestParameters.minimumMarketShare !== undefined) {
      queryParameters["MinimumMarketShare"] = requestParameters.minimumMarketShare;
    }

    if (requestParameters.maximumMarketShare !== undefined) {
      queryParameters["MaximumMarketShare"] = requestParameters.maximumMarketShare;
    }

    if (requestParameters.minimumMonthlyDelta !== undefined) {
      queryParameters["MinimumMonthlyDelta"] = requestParameters.minimumMonthlyDelta;
    }

    if (requestParameters.maximumMonthlyDelta !== undefined) {
      queryParameters["MaximumMonthlyDelta"] = requestParameters.maximumMonthlyDelta;
    }

    if (requestParameters.minimumCompTrx !== undefined) {
      queryParameters["MinimumCompTrx"] = requestParameters.minimumCompTrx;
    }

    if (requestParameters.maximumCompTrx !== undefined) {
      queryParameters["MaximumCompTrx"] = requestParameters.maximumCompTrx;
    }

    if (requestParameters.resultNumber !== undefined) {
      queryParameters["ResultNumber"] = requestParameters.resultNumber;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/Detail`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PlanFeedIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async payerDetailGet(requestParameters: PayerDetailGetRequest): Promise<PlanFeedIEnumerableControllerResponse> {
    const response = await this.payerDetailGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerPlanProfileAdditionalCoverageUrlGetRaw(
    requestParameters: PayerPlanProfileAdditionalCoverageUrlGetRequest,
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    if (requestParameters.planID === null || requestParameters.planID === undefined) {
      throw new runtime.RequiredError(
        "planID",
        "Required parameter requestParameters.planID was null or undefined when calling payerPlanProfileAdditionalCoverageUrlGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling payerPlanProfileAdditionalCoverageUrlGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.planID !== undefined) {
      queryParameters["PlanID"] = requestParameters.planID;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/PlanProfile/AdditionalCoverageUrl`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async payerPlanProfileAdditionalCoverageUrlGet(
    requestParameters: PayerPlanProfileAdditionalCoverageUrlGetRequest,
  ): Promise<StringControllerResponse> {
    const response = await this.payerPlanProfileAdditionalCoverageUrlGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerPlanProfileMarketSummaryGetRaw(
    requestParameters: PayerPlanProfileMarketSummaryGetRequest,
  ): Promise<runtime.ApiResponse<MarketSummaryIEnumerableControllerResponse>> {
    if (requestParameters.planID === null || requestParameters.planID === undefined) {
      throw new runtime.RequiredError(
        "planID",
        "Required parameter requestParameters.planID was null or undefined when calling payerPlanProfileMarketSummaryGet.",
      );
    }

    if (requestParameters.geographyType === null || requestParameters.geographyType === undefined) {
      throw new runtime.RequiredError(
        "geographyType",
        "Required parameter requestParameters.geographyType was null or undefined when calling payerPlanProfileMarketSummaryGet.",
      );
    }

    if (requestParameters.geography === null || requestParameters.geography === undefined) {
      throw new runtime.RequiredError(
        "geography",
        "Required parameter requestParameters.geography was null or undefined when calling payerPlanProfileMarketSummaryGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling payerPlanProfileMarketSummaryGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.planID !== undefined) {
      queryParameters["PlanID"] = requestParameters.planID;
    }

    if (requestParameters.geographyType !== undefined) {
      queryParameters["GeographyType"] = requestParameters.geographyType;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/PlanProfile/MarketSummary`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MarketSummaryIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async payerPlanProfileMarketSummaryGet(
    requestParameters: PayerPlanProfileMarketSummaryGetRequest,
  ): Promise<MarketSummaryIEnumerableControllerResponse> {
    const response = await this.payerPlanProfileMarketSummaryGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerPlanProfilePlanDataGetRaw(
    requestParameters: PayerPlanProfilePlanDataGetRequest,
  ): Promise<runtime.ApiResponse<PlanDataControllerResponse>> {
    if (requestParameters.planID === null || requestParameters.planID === undefined) {
      throw new runtime.RequiredError(
        "planID",
        "Required parameter requestParameters.planID was null or undefined when calling payerPlanProfilePlanDataGet.",
      );
    }

    if (requestParameters.geographyType === null || requestParameters.geographyType === undefined) {
      throw new runtime.RequiredError(
        "geographyType",
        "Required parameter requestParameters.geographyType was null or undefined when calling payerPlanProfilePlanDataGet.",
      );
    }

    if (requestParameters.geography === null || requestParameters.geography === undefined) {
      throw new runtime.RequiredError(
        "geography",
        "Required parameter requestParameters.geography was null or undefined when calling payerPlanProfilePlanDataGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling payerPlanProfilePlanDataGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.planID !== undefined) {
      queryParameters["PlanID"] = requestParameters.planID;
    }

    if (requestParameters.geographyType !== undefined) {
      queryParameters["GeographyType"] = requestParameters.geographyType;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/PlanProfile/PlanData`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => PlanDataControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async payerPlanProfilePlanDataGet(
    requestParameters: PayerPlanProfilePlanDataGetRequest,
  ): Promise<PlanDataControllerResponse> {
    const response = await this.payerPlanProfilePlanDataGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async payerPlanProfileTopHealthcareProvidersExcelGetRaw(
    requestParameters: PayerPlanProfileTopHealthcareProvidersExcelGetRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.planID === null || requestParameters.planID === undefined) {
      throw new runtime.RequiredError(
        "planID",
        "Required parameter requestParameters.planID was null or undefined when calling payerPlanProfileTopHealthcareProvidersExcelGet.",
      );
    }

    if (requestParameters.geographyType === null || requestParameters.geographyType === undefined) {
      throw new runtime.RequiredError(
        "geographyType",
        "Required parameter requestParameters.geographyType was null or undefined when calling payerPlanProfileTopHealthcareProvidersExcelGet.",
      );
    }

    if (requestParameters.geography === null || requestParameters.geography === undefined) {
      throw new runtime.RequiredError(
        "geography",
        "Required parameter requestParameters.geography was null or undefined when calling payerPlanProfileTopHealthcareProvidersExcelGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling payerPlanProfileTopHealthcareProvidersExcelGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.planID !== undefined) {
      queryParameters["PlanID"] = requestParameters.planID;
    }

    if (requestParameters.geographyType !== undefined) {
      queryParameters["GeographyType"] = requestParameters.geographyType;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.prescID !== undefined) {
      queryParameters["PrescID"] = requestParameters.prescID;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.columnKeys) {
      queryParameters["ColumnKeys"] = requestParameters.columnKeys;
    }

    if (requestParameters.columnNames) {
      queryParameters["ColumnNames"] = requestParameters.columnNames;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/PlanProfile/TopHealthcareProviders/Excel`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async payerPlanProfileTopHealthcareProvidersExcelGet(
    requestParameters: PayerPlanProfileTopHealthcareProvidersExcelGetRequest,
  ): Promise<void> {
    await this.payerPlanProfileTopHealthcareProvidersExcelGetRaw(requestParameters);
  }

  /**
   */
  async payerPlanProfileTopHealthcareProvidersGetRaw(
    requestParameters: PayerPlanProfileTopHealthcareProvidersGetRequest,
  ): Promise<runtime.ApiResponse<TopHealthcareProvidersIEnumerableControllerResponse>> {
    if (requestParameters.planID === null || requestParameters.planID === undefined) {
      throw new runtime.RequiredError(
        "planID",
        "Required parameter requestParameters.planID was null or undefined when calling payerPlanProfileTopHealthcareProvidersGet.",
      );
    }

    if (requestParameters.geographyType === null || requestParameters.geographyType === undefined) {
      throw new runtime.RequiredError(
        "geographyType",
        "Required parameter requestParameters.geographyType was null or undefined when calling payerPlanProfileTopHealthcareProvidersGet.",
      );
    }

    if (requestParameters.geography === null || requestParameters.geography === undefined) {
      throw new runtime.RequiredError(
        "geography",
        "Required parameter requestParameters.geography was null or undefined when calling payerPlanProfileTopHealthcareProvidersGet.",
      );
    }

    if (requestParameters.client === null || requestParameters.client === undefined) {
      throw new runtime.RequiredError(
        "client",
        "Required parameter requestParameters.client was null or undefined when calling payerPlanProfileTopHealthcareProvidersGet.",
      );
    }

    if (requestParameters.resultNumber === null || requestParameters.resultNumber === undefined) {
      throw new runtime.RequiredError(
        "resultNumber",
        "Required parameter requestParameters.resultNumber was null or undefined when calling payerPlanProfileTopHealthcareProvidersGet.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.planID !== undefined) {
      queryParameters["PlanID"] = requestParameters.planID;
    }

    if (requestParameters.geographyType !== undefined) {
      queryParameters["GeographyType"] = requestParameters.geographyType;
    }

    if (requestParameters.geography !== undefined) {
      queryParameters["Geography"] = requestParameters.geography;
    }

    if (requestParameters.client !== undefined) {
      queryParameters["Client"] = requestParameters.client;
    }

    if (requestParameters.indication !== undefined) {
      queryParameters["Indication"] = requestParameters.indication;
    }

    if (requestParameters.prescID !== undefined) {
      queryParameters["PrescID"] = requestParameters.prescID;
    }

    if (requestParameters.timePeriod !== undefined) {
      queryParameters["TimePeriod"] = requestParameters.timePeriod;
    }

    if (requestParameters.resultNumber !== undefined) {
      queryParameters["ResultNumber"] = requestParameters.resultNumber;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["Skip"] = requestParameters.skip;
    }

    if (requestParameters.sortSortField !== undefined) {
      queryParameters["Sort.SortField"] = requestParameters.sortSortField;
    }

    if (requestParameters.sortSortDirection !== undefined) {
      queryParameters["Sort.SortDirection"] = requestParameters.sortSortDirection;
    }

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/Payer/PlanProfile/TopHealthcareProviders`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TopHealthcareProvidersIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async payerPlanProfileTopHealthcareProvidersGet(
    requestParameters: PayerPlanProfileTopHealthcareProvidersGetRequest,
  ): Promise<TopHealthcareProvidersIEnumerableControllerResponse> {
    const response = await this.payerPlanProfileTopHealthcareProvidersGetRaw(requestParameters);
    return await response.value();
  }
}
