/* tslint:disable */
/* eslint-disable */
/**
 * Milliman.PullThroughPortal.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ResourceLibraryIEnumerableControllerResponse,
  ResourceLibraryIEnumerableControllerResponseFromJSON,
  ResourceLibraryIEnumerableControllerResponseToJSON,
} from "../models";

export interface ResourceLibraryGetRequest {
  apiVersion?: string;
}

/**
 *
 */
export class ResourceLibraryApi extends runtime.BaseAPI {
  /**
   */
  async resourceLibraryGetRaw(
    requestParameters: ResourceLibraryGetRequest,
  ): Promise<runtime.ApiResponse<ResourceLibraryIEnumerableControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.apiVersion !== undefined) {
      queryParameters["api-version"] = requestParameters.apiVersion;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
    }

    const response = await this.request({
      path: `/ResourceLibrary`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResourceLibraryIEnumerableControllerResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async resourceLibraryGet(
    requestParameters: ResourceLibraryGetRequest,
  ): Promise<ResourceLibraryIEnumerableControllerResponse> {
    const response = await this.resourceLibraryGetRaw(requestParameters);
    return await response.value();
  }
}
