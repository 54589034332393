import fileSaver from "file-saver";
import { QueryFunctionContext, useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useGlobalState } from "../data/global/hooks";
import { useHcpLandscapeState } from "../data/hcpLandscape/hooks";
import { HCPApi, HCPHCPLandscapeGetRequest, HCPLandscapeDropdowns, PrescFeed } from "../generate";
import { call } from "./api";

type ApiResponse = PrescFeed[] | null | undefined;
type ApiRequest = Omit<HCPHCPLandscapeGetRequest, "resultNumber"> & { resultNumber: number };
type FetchPage = (options: Omit<QueryFunctionContext, "queryKey">) => Promise<ApiResponse>;

const useHcpLandscapeFetch = (client: string): [ApiRequest, FetchPage] => {
  const { filters, sort } = useHcpLandscapeState();
  const { selectedGeoName, selectedGeoType } = useGlobalState();
  const requestParams = {
    client,
    resultNumber: 100,
    ...filters,
    level: selectedGeoType,
    geography: selectedGeoName,
    sortSortField: sort.field,
    sortSortDirection: sort.direction,
  };

  const fetch = async ({ pageParam = 0 }) => {
    const baseParams = {
      ...requestParams,
      skip: pageParam,
    };

    // Force cast to the expected type
    const queryParams = baseParams as unknown as HCPHCPLandscapeGetRequest;

    const { data } = await call(HCPApi).hCPHCPLandscapeGet(queryParams);
    return data;
  };

  return [requestParams as unknown as ApiRequest, fetch];
};

export const useHcpLandscapeApi = (client: string) => {
  const [requestParams, fetch] = useHcpLandscapeFetch(client);

  return useInfiniteQuery<ApiResponse, Error>(["hcp-landscape", requestParams], fetch, {
    enabled: Boolean(requestParams.client) && Boolean(requestParams.geography) && Boolean(requestParams.level),
    getNextPageParam: (lastPage, pages) =>
      lastPage && lastPage.length === 0 ? undefined : pages.length * requestParams.resultNumber,
  });
};

export const useHcpLandscapeExport = (client: string, columnKeys: string[], columnNames: string[]) => {
  const [queryParams] = useHcpLandscapeFetch(client);
  return useMutation<void, Error>(async () => {
    const response = await call(HCPApi).hCPHCPLandscapeExcelGetRaw({
      columnKeys,
      columnNames,
      ...queryParams,
    });
    const blob = await response.raw.blob();
    const file = new File([blob], "export.xlsx");
    fileSaver.saveAs(file);
  });
};

export const useHcpLandscapeDropdowns = (client: string) => {
  const fetch = async () => {
    const { data } = await call(HCPApi).hCPDropdownsGet({ client });
    return data;
  };
  type ApiResponse = HCPLandscapeDropdowns | undefined;
  return useQuery<ApiResponse, Error>(["hcp-dropdowns", client], fetch);
};
