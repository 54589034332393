/* eslint-disable no-console */
import React, { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import RangeInput from "../../components/Inputs/RangeInput";
import FilterModal from "../../components/Modal/FilterModal";
import { useModal } from "../../components/Modal/useModal";
import SearchBox from "../../components/SearchBox/SearchBox";
import { useClientHCPLandscapeColumnsConfiguration } from "../../data/global/useProductConfiguration";
import { clearAdvancedFilters as clearAction, updateFilters as filterAction } from "../../data/hcpLandscape/actions";
import { useHcpLandscapeDispatch, useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import hcpLandscapeReducer from "../../data/hcpLandscape/reducer";
import { nullToUndefined } from "../../utils/typeUtils";

const DetailFiltersModal: React.FC<{ client: string }> = ({ client }) => {
  const { t, i18n } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(0);
  const { setModal } = useModal();
  const hcpLandscapeState = useHcpLandscapeState();
  const { updateFilters, clearAdvancedFilters } = useHcpLandscapeDispatch();
  const [{ filters }, dispatch] = useReducer(hcpLandscapeReducer, hcpLandscapeState);

  const clear = () => {
    dispatch(clearAction());
    clearAdvancedFilters();
    setRefreshKey((key) => key + 1);
  };

  const filter = () => {
    updateFilters(filters);
    setModal(undefined);
  };

  const config = useClientHCPLandscapeColumnsConfiguration(client);

  const marketTrxHidden = !config?.includes("mkt_trx");

  return (
    <FilterModal onClear={clear} onFilter={filter} title={t(`hcpLandscape.hcpLandscapeFilters.${client}`)}>
      <SearchBox
        value={filters.name}
        handleChange={(value) => dispatch(filterAction({ name: value }))}
        label={t([`hcpLandscape.hcps.${client}`, `hcpLandscape.hcps.default`])}
      />
      <RangeInput
        key={`favorableAccessPercent-${refreshKey}`}
        range={{
          min: filters.favorableAccessPercentMin,
          max: filters.favorableAccessPercentMax,
          rangeType: filters.favorableAccessPercentRangeType!,
        }}
        setRange={(range) =>
          dispatch(
            filterAction({
              favorableAccessPercentMin: nullToUndefined(range.min),
              favorableAccessPercentMax: nullToUndefined(range.max),
              favorableAccessPercentRangeType: range.rangeType,
            }),
          )
        }
        label={
          i18n.exists(`hcpLandscape.favorableAccess.${client}`)
            ? t(`hcpLandscape.favorableAccess.${client}`)
            : t(`hcpLandscape.favorableAccess.default`)
        }
        allowNegative={false}
      />
      <RangeInput
        key={`favorableAccessChangePercent-${refreshKey}`}
        range={{
          min: filters.favorableAccessChangePercentMin,
          max: filters.favorableAccessChangePercentMax,
          rangeType: filters.favorableAccessChangePercentRangeType!,
        }}
        setRange={(range) =>
          dispatch(
            filterAction({
              favorableAccessChangePercentMin: nullToUndefined(range.min),
              favorableAccessChangePercentMax: nullToUndefined(range.max),
              favorableAccessChangePercentRangeType: range.rangeType,
            }),
          )
        }
        label={
          i18n.exists(`hcpLandscape.changeInFa.${client}`)
            ? t(`hcpLandscape.changeInFa.${client}`, { channel: filters.benificiaryType })
            : t(`hcpLandscape.changeInFa.default`)
        }
        allowNegative={false}
      />
      <RangeInput
        key={`overallAccessPercent-${refreshKey}`}
        range={{
          min: filters.overallAccessPercentMin,
          max: filters.overallAccessPercentMax,
          rangeType: filters.overallAccessPercentRangeType!,
        }}
        setRange={(range) =>
          dispatch(
            filterAction({
              overallAccessPercentMin: nullToUndefined(range.min),
              overallAccessPercentMax: nullToUndefined(range.max),
              overallAccessPercentRangeType: range.rangeType,
            }),
          )
        }
        label={
          i18n.exists(`hcpLandscape.overallAccess.${client}`)
            ? t(`hcpLandscape.overallAccess.${client}`)
            : t(`hcpLandscape.overallAccess.default`)
        }
        allowNegative={false}
      />
      {!marketTrxHidden && (
        <>
          <RangeInput
            key={`marketTrx-${refreshKey}`}
            range={{
              min: filters.marketTrxMin,
              max: filters.marketTrxMax,
              rangeType: filters.marketRangeType!,
            }}
            setRange={(range) =>
              dispatch(
                filterAction({
                  marketTrxMin: nullToUndefined(range.min),
                  marketTrxMax: nullToUndefined(range.max),
                  marketRangeType: range.rangeType,
                }),
              )
            }
            label={
              i18n.exists(`hcpLandscape.marketTrx.${filters.timePeriod}.${client}`)
                ? t(`hcpLandscape.marketTrx.${filters.timePeriod}.${client}`)
                : t(`hcpLandscape.marketTrx.${filters.timePeriod}.default`)
            }
            allowNegative={false}
          />
          <RangeInput
            key={`cibinqoTrx-${refreshKey}`}
            range={{
              min: filters.trxMin,
              max: filters.trxMax,
              rangeType: filters.trxRangeType!,
            }}
            setRange={(range) =>
              dispatch(
                filterAction({
                  trxMin: nullToUndefined(range.min),
                  trxMax: nullToUndefined(range.max),
                  trxRangeType: range.rangeType,
                }),
              )
            }
            label={
              i18n.exists(`hcpLandscape.productTrx.${filters.timePeriod}.${client}`, { product: client })
                ? t(`hcpLandscape.productTrx.${filters.timePeriod}.${client}`, { product: client })
                : t(`hcpLandscape.productTrx.${filters.timePeriod}.default`, { product: client })
            }
            allowNegative={false}
          />
        </>
      )}
      <RangeInput
        key={`productShare-${refreshKey}`}
        range={{
          min: filters.productShareMin,
          max: filters.productShareMax,
          rangeType: filters.productShareRangeType!,
        }}
        setRange={(range) =>
          dispatch(
            filterAction({
              productShareMin: nullToUndefined(range.min),
              productShareMax: nullToUndefined(range.max),
              productShareRangeType: range.rangeType,
            }),
          )
        }
        label={
          i18n.exists(`hcpLandscape.productShare.${filters.timePeriod}.${client}`, { product: client })
            ? t(`hcpLandscape.productShare.${filters.timePeriod}.${client}`, { product: client })
            : t(`hcpLandscape.productShare.${filters.timePeriod}.default`)
        }
        allowNegative={false}
      />
    </FilterModal>
  );
};

export default DetailFiltersModal;
