import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GeographyType } from "../../components/GeoSelect/GeoTypes";
import {
  CoverageStatus,
  MarketSummary,
  PlanFeed,
  PrescFeed,
  PrescribingHabits,
  TopHealthcareProviders,
} from "../../generate";

type ProductPrintConfiguration = {
  hcpPrintMessages: boolean;
  hcpEmailMessages: boolean;
  resourceLibrary: boolean;
  useIsPIForEmailMessages: boolean;
  useIsPIForPrintMessages: boolean;
  defaultIsPIEmail: boolean;
  defaultIsPIPrint: boolean;
  printEnabledForFRM: boolean;
};

type ProductPayerLandscapeConfiguration = {
  enabled?: boolean;
  visibleColumns?: (keyof PlanFeed)[];
  visibleFrmColumns?: (keyof PlanFeed)[];
  productComparison?: boolean;
  detailedViewProductComp?: TableConfiguration<keyof PlanFeed>;
  indicationFiltering?: boolean;
};

type ProductPlanProfileConfiguration = {
  hiddenForGeneral?: boolean;
  indicationFiltering?: boolean;
  topTLC: ProductPlanProfileTopHCPConfiguration;
  pharmacyBenefitManagers: boolean;
  hideMarketSummary?: boolean;
  marketSummary?: TableConfiguration<keyof MarketSummary>;
};

type ProductPlanProfileTopHCPConfiguration = {
  dataKeys: (keyof TopHealthcareProviders)[];
  sortTopTLC?: boolean;
  sortTopTLCDataKey?: keyof TopHealthcareProviders;
  sortTopTLCDirection?: string;
};

type ProductHCPLandscapeConfiguration = {
  enabled?: boolean;
  visibleColumns?: (keyof PrescFeed | "full_name")[];
  visibleFrmColumns?: (keyof PrescFeed | "full_name")[];
  indicationFiltering?: boolean;
};

type ProductHCPProfileConfiguration = {
  indicationFiltering?: boolean;
  prescribingHabits?: TableConfiguration<keyof PrescribingHabits>;
  hideHabits?: boolean;
  hideHabitsTotalMarket?: boolean;
  costAndCoverageMessages: boolean;
  planInformation?: TableConfiguration<keyof CoverageStatus>;
};

type TableConfiguration<T> = {
  dataKeys?: T[];
};

type RouteConfig = {
  path?: string;
  label?: string;
  [key: string]: RouteConfig | string | undefined;
};

type ProductConfiguration = {
  products?: string[];
  print?: ProductPrintConfiguration;
  payerLandscape?: ProductPayerLandscapeConfiguration;
  planProfile?: ProductPlanProfileConfiguration;
  hcpLandscape?: ProductHCPLandscapeConfiguration;
  hcpProfile?: ProductHCPProfileConfiguration;
  defaultGeographyLevel?: GeographyType | null;
  rootGeographyLevel?: GeographyType | null;
  routeOverrides?: RouteConfig;
  hasHcpAccessAsFrm?: boolean;
  showFootnote?: boolean;
  hasAdtMktTrxTrans?: boolean;
  hidePlanDataTooltip?: boolean;
  hidePlanDataLastRow?: boolean;
  hidePlanDataRight?: boolean;
  hideCompStatusColumnsAsFrm?: boolean;
  howToVideo?: string;
  howToFrmVideo?: string;
  hideHowToVideoFromFrm?: boolean;
  indicationFilters?: { value: string; label: string; disabled?: boolean }[];
};

export const useClientConfiguration = (client?: string | null) => {
  const [t] = useTranslation("product-configuration");
  return useMemo(() => {
    const defaultConfiguration: ProductConfiguration = {};
    if (!client) {
      return defaultConfiguration;
    }
    const configuration = t(client, { returnObjects: true }) as ProductConfiguration;
    return configuration || defaultConfiguration;
  }, [t, client]);
};

export const useClientPrintConfiguration = (client?: string | null) => {
  const defaultPrintConfiguration: ProductPrintConfiguration = {
    hcpPrintMessages: true,
    hcpEmailMessages: true,
    resourceLibrary: true,
    useIsPIForEmailMessages: true,
    useIsPIForPrintMessages: true,
    defaultIsPIEmail: true,
    defaultIsPIPrint: true,
    printEnabledForFRM: false,
  };
  const config = useClientConfiguration(client);
  return config.print || defaultPrintConfiguration;
};

export const useClientPayerLandscapeConfiguration = (client?: string | null) => {
  const defaultPayerLandscapeConfiguration: ProductPayerLandscapeConfiguration = {
    enabled: true,
    productComparison: true,
    indicationFiltering: false,
    detailedViewProductComp: {
      dataKeys: [
        "m_plan_name",
        "lives",
        "cc_status",
        "rel_status",
        "comp_cc_status",
        "comp_rel_status",
        "mkt_trx",
        "prod_trx",
        "mkt_share",
        "share_delta",
        "comp_prod_trx",
      ],
    },
  };
  const config = useClientConfiguration(client);
  return config.payerLandscape || defaultPayerLandscapeConfiguration;
};

export const useClientPayerLandscapeColumnsConfiguration = (client?: string | null, columnsProfileHidden?: boolean) => {
  const defaultPayerLandscapeConfiguration: ProductPayerLandscapeConfiguration = {
    visibleColumns: [
      "m_plan_name",
      "pbm_vendor",
      "lives",
      "pct_bob",
      "cc_status",
      "rel_status",
      "comp_cc_status",
      "comp_rel_status",
      "mkt_trx",
      "prod_trx",
      "mkt_share",
      "share_delta",
      "comp_prod_trx",
    ],
    visibleFrmColumns: [
      "m_plan_name",
      "pbm_vendor",
      "lives",
      "cc_status",
      "rel_status",
      "comp_cc_status",
      "comp_rel_status",
      "mkt_trx",
      "prod_trx",
      "mkt_share",
      "share_delta",
    ],
  };
  const config = useClientConfiguration(client);

  return columnsProfileHidden
    ? defaultPayerLandscapeConfiguration.visibleFrmColumns
    : config?.payerLandscape?.visibleColumns || defaultPayerLandscapeConfiguration.visibleColumns;
};

export const useClientPlanProfileConfiguration = (client?: string | null) => {
  const defaultPlanProfileConfiguration: ProductPlanProfileConfiguration = {
    pharmacyBenefitManagers: true,
    hiddenForGeneral: false,
    indicationFiltering: false,
    topTLC: {
      dataKeys: ["topHCPs", "marketShare", "mkt_trx"],
    },
  };
  const config = useClientConfiguration(client);
  return config.planProfile || defaultPlanProfileConfiguration;
};

export const useClientPlanProfileMarketSummaryConfiguration = (client?: string | null) => {
  const defaultClientPlanProfileMarketSummaryConfiguration: TableConfiguration<keyof MarketSummary> = {
    dataKeys: ["coverageStatus", "relativeStatus", "tRx", "marketShare"],
  };
  const config = useClientPlanProfileConfiguration(client);
  return config.marketSummary || defaultClientPlanProfileMarketSummaryConfiguration;
};

export const useClientHCPProfileConfiguration = (client?: string | null) => {
  const defaultClientHCPProfile: ProductHCPProfileConfiguration = {
    indicationFiltering: false,
    costAndCoverageMessages: true,
  };
  const config = useClientConfiguration(client);
  return config.hcpProfile || defaultClientHCPProfile;
};

export const useClientHCPLandscapeConfiguration = (client?: string | null) => {
  const defaultClientHCPLandscape: ProductHCPLandscapeConfiguration = {
    enabled: true,
    indicationFiltering: false,
  };
  const config = useClientConfiguration(client);
  return config.hcpLandscape || defaultClientHCPLandscape;
};

export const useClientHCPProfilePrescribingHabitsConfiguration = (client?: string | null) => {
  const defaultPrescribingHabitsConfiguration: TableConfiguration<keyof PrescribingHabits> = {
    dataKeys: [
      "product",
      "commercialTrx",
      "commercialMarketShare",
      "medicaidTrx",
      "medicaidMarketShare",
      "allChannelsTrx",
      "allChannelsMarketShare",
    ],
  };
  const config = useClientHCPProfileConfiguration(client);
  return config?.prescribingHabits || defaultPrescribingHabitsConfiguration;
};

export const useClientHCPProfilePlanInformationConfiguration = (client?: string | null) => {
  const defaultPlanInformationConfiguration: TableConfiguration<keyof CoverageStatus> = {
    dataKeys: [
      "m_plan_name",
      "channel",
      "bob_pct_ovrl",
      "bob_pct_chnl",
      "cc_status",
      "rel_status",
      "prod_trx",
      "mkt_trx",
      "prod_share",
    ],
  };
  const config = useClientHCPProfileConfiguration(client);
  return config?.planInformation || defaultPlanInformationConfiguration;
};

export const useClientPlanProfileTopHCPConfiguration = (client?: string | null) => {
  const defaultPlanProfileTopHCPConfiguration: ProductPlanProfileTopHCPConfiguration = {
    dataKeys: [
      "topHCPs",
      "bob_pct_ovrl",
      "prod_trx",
      "mkt_trx",
      "fav_pct",
      "acc_pct",
      "prod_share",
      "chnl_prod_trx",
      "chnl_mkt_trx",
      "chnl_prod_share",
    ],
    sortTopTLC: false,
  };
  const config = useClientPlanProfileConfiguration(client);
  return config?.topTLC || defaultPlanProfileTopHCPConfiguration;
};

export const useClientDetailTabColumnConfiguration = (client?: string | null) => {
  const defaultDetailsConfiguration: TableConfiguration<keyof PlanFeed> = {
    dataKeys: ["m_plan_name", "lives", "cc_status", "rel_status", "mkt_trx", "prod_trx", "mkt_share", "share_delta"],
  };
  const config = useClientPayerLandscapeConfiguration(client);
  return config?.detailedViewProductComp || defaultDetailsConfiguration;
};

export const useClientHCPLandscapeColumnsConfiguration = (client?: string | null, columnsProfileHidden?: boolean) => {
  const defaultHcpLandscapeConfiguration: ProductHCPLandscapeConfiguration = {
    visibleColumns: ["full_name", "fav_pct", "delta_fa", "acc_pct", "mkt_trx", "prod_trx", "prod_share"],
    visibleFrmColumns: ["full_name", "fav_pct", "delta_fa", "acc_pct"],
  };
  const config = useClientConfiguration(client);

  return columnsProfileHidden
    ? defaultHcpLandscapeConfiguration.visibleFrmColumns
    : config?.hcpLandscape?.visibleColumns || defaultHcpLandscapeConfiguration.visibleColumns;
};

export const useClientDefaultGeographyLevelConfiguration = (client?: string | null) => {
  const config = useClientConfiguration(client);
  return config?.defaultGeographyLevel || null;
};

export const useClientRootGeographyLevelConfiguration = (client?: string | null) => {
  const config = useClientConfiguration(client);
  return config?.rootGeographyLevel || null;
};

export const useClientRouteOverrides = (client?: string | null) => {
  const config = useClientConfiguration(client);
  return config?.routeOverrides || null;
};

export const useClientIndicationFiltersconfiguration = (client?: string | null) => {
  const config = useClientConfiguration(client);
  return config?.indicationFilters;
};
